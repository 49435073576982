import {
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spacer,
  VStack
} from '@chakra-ui/react';

export const ItemSkeleton = () => {
  return (
    <VStack align={'stretch'} mt='4'>
      <HStack>
        <SkeletonCircle size='6' />
        <Skeleton w={'60px'} height='24px' />
        <Spacer />
        <Skeleton w={'88px'} height='24px' />
      </HStack>
      <SkeletonText noOfLines={4} spacing='2' />
    </VStack>
  );
};
