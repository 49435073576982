import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { FcBookmark } from 'react-icons/fc';

import { useInfiniteScroll } from 'hooks/useInfinteScroll';
import { ItemSkeleton } from 'components/shared/Skeleton';
import BookmarkCard from 'components/shared/BookmarkCard';
import { useSession } from 'next-auth/react';
import { useToast, useTranslation } from 'hooks';
import AppHead from 'utls/AppHead';

const Bookmarks = ({}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const toast = useToast();
  const { data: session }: any = useSession();
  const params = {
    name_space: 'arageek/v1',
    name: 'get-bookmarks',
    page: 1,
    per_page: 5,
    token: session?.accessToken
  };

  const { refetch, isLoading, items, loadMore, hasNextPage } =
    useInfiniteScroll(params);

  useEffect(() => {
    if (session?.accessToken) refetch();
    else {
      toast({
        title: t('general.toastMessages.alert'),
        description: t('general.toastMessages.login_first'),
        status: 'warning'
      });
      router.push('/');
    }
  }, [session?.accessToken]);

  if (!items) {
    return null;
  }

  return (
    <Box pt='0'>
      <AppHead title={t('bio.my_magazin')} />
      <HStack
        alignItems={'center'}
        borderBottom={'2px solid black'}
        display='inline-flex'
        spacing={1}
      >
        <Icon as={FcBookmark} fontSize={'30px'} mt='2' />
        <Heading pt='4' as='h4' fontSize={'h2'} pb='3' mb='6'>
          {t('bio.my_magazin')}
        </Heading>
      </HStack>
      {items.length === 0 && !isLoading && (
        <Center mt='10'>
          <Heading as='h4' fontSize={'h3'} textAlign='center' p='4'>
            😔
            <br />
            {t('bio.empty_reading_list')}
          </Heading>
        </Center>
      )}

      <VStack
        align={'stretch'}
        spacing='6'
        divider={<StackDivider borderColor='gray.200' />}
        py='10'
      >
        {items?.map(item => (
          <BookmarkCard key={item?.id} {...item} refetchBookmarks={refetch} />
        ))}

        {isLoading &&
          Array(10)
            .fill(0)
            .map((_, i) => <ItemSkeleton key={i} />)}

        {hasNextPage && (
          <Center>
            <Button
              w='full'
              onClick={loadMore}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {t('general.more')}
            </Button>
          </Center>
        )}
      </VStack>
    </Box>
  );
};

export default Bookmarks;
