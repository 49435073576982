import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

const AppHead = ({ title }) => {
  const router = useRouter();
  return (
    <Head>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width' />
      <meta name='robots' content='index,follow' />
      <meta name='description' content={'Arageek Community'} />

      <meta
        property='og:title'
        content={title ? `Arageek - ${title}` : 'Arageek'}
      />
      <meta property='og:description' content={'Arageek Community'} />
      <meta
        property='og:url'
        content={
          router.locale === router.defaultLocale
            ? 'https://arageek.com/' + router.asPath
            : 'https://arageek.com/' + '/' + router.locale + router.asPath
        }
      />
      <link
        rel='canonical'
        href={
          router.locale === router.defaultLocale
            ? 'https://arageek.com/' + router.asPath
            : 'https://arageek.com/' + '/' + router.locale + router.asPath
        }
      />
      <title>{title ? `${title} - Arageek` : 'Arageek'}</title>
    </Head>
  );
};

export default AppHead;
