import { Box, Heading, HStack, LinkBox, Text, VStack } from '@chakra-ui/react';

import { NextChakraLinkOverlay } from '../wrappers';
import { Image } from '.';
import BookmarkButton from './BookmarkButton';
import { useState } from 'react';

const BookmarkCard: React.FC = ({
  id,
  title,
  link,
  thumb,
  excerpt,
  post_type,
  refetchBookmarks
}: any) => {
  const url = `${link}`;
  const [isBooked, setIsBooked] = useState();
  return (
    <LinkBox as='article' pos={'relative'} role='group'>
      <HStack align={'stretch'}>
        <VStack align={'stretch'} spacing={6} w={2 / 3}>
          <Heading
            as='span'
            fontSize={{ base: 'h4', md: 'h3' }}
            fontWeight='medium'
          >
            <NextChakraLinkOverlay href={url}>
              <Text
                lineHeight={'1.5'}
                as='span'
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </NextChakraLinkOverlay>
          </Heading>

          <Box zIndex={10}>
            <BookmarkButton
              isBooked={true}
              id={id}
              refetchBookmarks={refetchBookmarks}
              setIsBooked={setIsBooked}
            />
          </Box>
          {/* <AuthorInfo author={author} hasNoSocial /> */}
        </VStack>
        <Box w={1 / 3}>
          <NextChakraLinkOverlay href={url}>
            <Box pos={'relative'}>
              <Image
                image={{ url: thumb }}
                ratio={16 / 9}
                rounded='md'
                boxShadow={'xl'}
                sizes={'(min-width: 62em) 15vw, 30vw'}
              />
            </Box>
          </NextChakraLinkOverlay>
        </Box>
      </HStack>
    </LinkBox>
  );
};

export default BookmarkCard;
