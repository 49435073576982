import StructuredData from 'components/gutenberg/StructuredData';
import { SeoHead } from 'components/system';
import { useRouteData } from 'hooks';
import { memo } from 'react';
import postTypesDirectory from './postTypesDirectory';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Bookmarks from 'components/bookmarks/Bookmarks';
const MultiPostRenderer = dynamic(() => import('./MultiPostRenderer'));
const DefaultLayout = dynamic(() => import('../layouts/DefaultLayout'));

const PostTypeRendererC = () => {
  const data = useRouteData();
  const router = useRouter();

  if (!data) {
    return null;
  }

  const {
    object_type,
    type,
    next_posts,
    yoast_head,
    gutenberg_blocks,
    id,
    acf,
    menus,
    comments
  } = data;

  const Component =
    postTypesDirectory?.[object_type]?.[type] ??
    postTypesDirectory?.['post']?.[type];

  const hasNextPosts = Array.isArray(next_posts) && next_posts?.length > 0;

  if (['podcast', 'tv'].includes(type)) {
    return (
      <>
        <Component
          data={data}
          article_summary={data?.article_summary}
          comments={comments}
        />
        <StructuredData singleVideoData={data} />
      </>
    );
  }
  if (type === 'page') {
    return (
      <DefaultLayout>
        {type === 'page' && Boolean(Component) ? (
          <>
            <Component
              data={data}
              article_summary={data?.article_summary}
              comments={comments}
            />
            <SeoHead {...{ yoast_head }} />
          </>
        ) : null}
      </DefaultLayout>
    );
  }

  if (object_type === 'post') {
    return (
      <DefaultLayout hasClosingButtonPlace={true}>
        <MultiPostRenderer
          firstItem={data}
          article_summary={data?.article_summary}
          comments={comments}
        />
      </DefaultLayout>
    );
  }

  if (object_type === 'bookmarks') {
    return (
      <DefaultLayout>
        <Bookmarks article_summary={data?.article_summary} />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Component
        data={data}
        article_summary={data?.article_summary}
        comments={comments}
      />
      <SeoHead {...{ yoast_head }} />
    </DefaultLayout>
  );
};

export default memo(PostTypeRendererC);
