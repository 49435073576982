import Head from 'next/head';

export default function StructuredData({ data, singleVideoData }) {
  const Items = data?.map(({ initialData }) => initialData?.items);
  const ItemsArray = Items?.flatMap(item => item);
  const itemListElement = ItemsArray?.map((item, index) => {
    const imageUrl = item?._embedded?.['wp:featuredmedia']?.[0]?.source_url;
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Movie',
        url: item?.link,
        name: item?.title?.rendered,
        image: imageUrl,
        dateCreated: item?.date,
        director: {
          '@type': 'Person',
          name: item?.acf?.movie_director_name
        },
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: item?.acf?.movie_rating / 2
          },
          name: item?.title?.rendered ?? 'Arageek',
          author: {
            '@type': 'Person',
            name:
              item?.acf?.movie_author_name && item?.acf?.movie_author_name != ''
                ? item?.acf?.movie_author_name
                : 'Arageek'
          },
          reviewBody: item?.acf?.movie_review_text
        }
      }
    };
  });
  const MovieSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: itemListElement
  };

  const VideoSchema = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: singleVideoData?.title?.rendered,
    description: singleVideoData?.content?.rendered,
    thumbnailUrl: [singleVideoData?.video_details?.thumb_url],
    uploadDate: singleVideoData?.date,
    duration: singleVideoData?.video_details?.duration,
    contentUrl: singleVideoData?.link,
    embedUrl: singleVideoData?.link
  };

  if (Array.isArray(ItemsArray)) {
    return (
      <Head>
        <script
          key='structured-data'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(MovieSchema)
          }}
        />
      </Head>
    );
  }

  if (singleVideoData) {
    return (
      <Head>
        <script
          key='structured-data'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(VideoSchema)
          }}
        />
      </Head>
    );
  }
}
